<template>
  <div v-if="event">
    <Environment v-if="environment != 'production'"/>
    <Header class="trainingscheduleheader"/>
    <Toast/>
    <div class="container-fluid quotecontainer">
      <div class="container quotetext"> 
        Jacinta Gallant is a 
        <span class="largetext">collaborative lawyer, mediator, trainer </span> 
        <span class="line"><span class="largetext">and presenter</span> in </span>
        <span> <br/>Charlottetown, Prince Edward Island.</span>
      </div>
    </div>

    <div class="container-fluid textured" style="padding-top:50px; padding-bottom:50px;text-align:left;">     
    <div class="container">
      <div class="row">
        <div class="col-xs-7">
          <h1 style="margin-top: 0px;">Event Details</h1>
        </div>
        <div class="col-xs-5">
          <div @click="showAllEvents()" class="back-button"><span class="glyphicon glyphicon-chevron-left"></span> All Events</div>
        </div>
      </div>
      <h2>{{ event.title }}</h2>
      <h3>{{ event.subTitle }}
        <span v-show="event.regStatus === 'soldOut'" class="subtitle-info sold-out">- Sold Out 
          <span class="glyphicon glyphicon-exclamation-sign"></span>
        </span>
        
        <span v-show="event.regStatus === 'notYet'" class="subtitle-info opening">- Registration Opening <b>SOME DATE</b> 
          <span class="glyphicon glyphicon-calendar"></span>
        </span>
        
        <span v-show="event.regStatus === 'closed'" class="subtitle-info closed">- Closed for Registration 
          <span class="glyphicon glyphicon-remove-circle"></span>
        </span>
        
      </h3>
      <!--<p class="post-date">{{manicuredDate(event.date)}} and {{manicuredDate(event.endDate)}}</p>-->
      <p class="event-location">{{ event.location }}</p>
      <br/>   
      </div>
    </div>    

    <div class="container-fluid maincontentcontainer">     
    <div class="container">
      <div>
      <ul class="nav nav-tabs" role="tablist">
        <li role="presentation" class="active"><a href="#about" aria-controls="about" role="tab" data-toggle="tab" v-if="event.description">About</a></li>
        
        <li role="presentation"><a href="#program" aria-controls="program" role="tab" data-toggle="tab" v-if="event.program">Program</a></li>
        
        <li role="presentation"><a href="#accommodations" aria-controls="accommodations" role="tab" data-toggle="tab" v-if="event.accommodation">Accommodations</a></li>
        
        <li role="presentation"><a href="#trainers" aria-controls="trainers" role="tab" data-toggle="tab" v-if="trainers.length >= 1">Trainers</a></li>

        <li role="presentation"><a href="#register" aria-controls="Register" role="tab" data-toggle="tab" v-if="event.externalRegistrationURL">Register</a></li>
        
      </ul>
      <div class="tab-content">
        <div role="tabpanel" class="tab-pane anchor active" id="about" v-if="event.description">
          <h3 style="text-align: left;">About This Event</h3>
          <div class="row">
            <div class="col-xs-12 col-sm-8">
              <p v-html="event.description" style="text-align: left;"></p>
              <!--
              <div class=" col-xs-12 col-sm-6 col-md-4 price-box">
                <div class=" col-xs-12 col-sm-6 col-md-4 price-box">
                  <h3 style="text-align: left;">Event Cost</h3>
                  <h4>${{ event.cost }} CAD</h4>
                  <small style="font-size: 73%; float: right;">Plus tax</small>
                </div>
              </div>
              -->
            </div>
            <div class="col-xs-12 col-sm-4" v-if="event.imageFilename">
              <img :src="baseURL + eventImagesPath + event.imageFilename" class="img-responsive rounded">
            </div>
          </div>
        </div>

        <div role="tabpanel" class="tab-pane anchor " id="program" v-if="event.program">
          <h3 style="text-align: left;">Event Program</h3>
          <p v-html="event.program" style="text-align: left;"></p>
        </div>
        
        <div role="tabpanel" class="tab-pane anchor " id="accommodations" v-if="event.accommodation">
          <h3 style="text-align: left;">Event Accommodations</h3>
          <p v-html="event.accommodation" style="text-align: left;"></p>
          
        </div>

        <div role="tabpanel" class="tab-pane anchor " id="register">
          <div class=" col-xs-12 col-sm-8">
            <h3 style="text-align: left;">Register For This Event</h3>
          </div>
          
          <div class="col-xs-12"> Registration for this event can be completed externally!<br/><br/><br/>
            <a  class="gotobutton"  :href="`http://${event.externalRegistrationURL}`" target="_blank">Visit Registration Website</a><br/><br/><br/></div>
          </div>

        <div role="tabpanel" class="tab-pane anchor " id="trainers" style="text-align: left;" v-if="trainers.length >= 1">
          <h3>Meet The Trainers</h3>
            <div  class="trainer-section" v-for="(trainer, index) in trainers" :key="index">
              <div style="width: 80%">
                <h4> {{ trainer.name }} <span class="event-location"> {{ trainer.title }}</span></h4>
                <p class="event-location">{{ trainer.home }}</p>
                <p>{{ trainer.biography }}</p>
              </div>
              <div style="margin: auto;">
                <img class="trainer-profile-image" :src="baseURL + trainerImagesPath + trainer.imageFilename">      
              </div>
            </div>
        </div>
      </div>
    </div>
    </div>
    </div>
    <Footer/>
  </div>
</template>

<script>
import { ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'

// Schema 
// import Event from '../schemas/Event.js'
// import Trainer from '../schemas/Trainer.js'

// Components
import Header from '../components/Header.vue'
import Footer from '../components/Footer.vue'

// API Services
import EventServices from '../services/EventServices.js'
import TrainerServices from '../services/TrainerServices.js'

// Primevue
import { useToast } from 'primevue/usetoast'

export default {
  name: 'EventDetail',
  components: {
    Header, 
    Footer,
  },
  setup() {
    const toast = useToast()
    const router = useRouter()
    const route = useRoute()
    const event = ref()
    const trainers = ref([])
    const baseURL = process.env.VUE_APP_API_SERVER
    const environment = process.env.VUE_APP_ENVIRONMENT
    const eventImagesPath = process.env.VUE_APP_EVENT_IMAGES
    const trainerImagesPath = process.env.VUE_APP_TRAINER_IMAGES

    
    EventServices.getEventDetail(route.query.e)
    .then((response) => {
      event.value = response.data
      TrainerServices.getTrainersForEvent(event.value.trainers)
      .then((response) => {
        trainers.value = response.data
      })
    })
    
    
    const manicuredDate = (value) => {
      var date = new Date(value)
      date = date.toLocaleString("en-CA", {
      month: 'long',
      day: '2-digit',
      year: 'numeric',
      timeZone: 'UTC'
      })
      if(date == "Invalid Date") {
          date = "--"
      }
      return date
    }

    const showAllEvents = () => {
      router.push({ name:"Events" })
    }

    return {
      toast,
      environment,
      event, 
      trainers, 
      baseURL, 
      eventImagesPath,
      trainerImagesPath,
      manicuredDate,
      showAllEvents
    }
  },
  methods: {
    // showAllEvents() {
    //   this.$emit("hideDetail")
    // }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.trainer-section {
  display: flex;
  flex-direction: column nowrap;
  margin-top: 25px;
}
.trainer-profile-image {
  display: flex;
  flex-direction: column nowrap;
  width: 150px;
  border-width: 1px;
  border-radius: 75px;
}
</style>
