import axios from 'axios'

const apiClient = axios.create ({
    baseURL: process.env.VUE_APP_API_SERVER,
    withCredentials: false,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
        //'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
})

export default {   
    getAllEvents() {
        return apiClient.get('api/v1/events')
    },
    getAvailableEvents() {
      // This call ONLY returns events which are not summer sessions and are active 24-Dec-2021
        return apiClient.get('api/v1/events-available')
    },
    getEventDetail(eventID) {
        return apiClient.post('api/v1/event-detail/' + eventID)
    },
    addEvent(newEvent) {
        let user = JSON.parse(localStorage.getItem('user'))
        return apiClient.post('api/v1/create-event', newEvent, {
            headers: {
                'Authorization': `Bearer ${user.token}`
            }
        })
    },
    deleteEvent(eventID) {
        let user = JSON.parse(localStorage.getItem('user'))
        return apiClient.get('api/v1/event/delete/' + eventID, {
            headers: { 
                'Authorization': `Bearer ${user.token}`
            }
        })
    },
    getAllSummerSessions() {
        return apiClient.get('api/v1/events/summer-sessions')
    },
    getSummerSessionCount() {
        return apiClient.get('api/v1/events/summer-sessions/total/count')
    },
    saveImageToFileSystem(fileName, image) {
      return apiClient.post('api/v1/upload/event/images', {files: [{filename: fileName, data:image}]})
    }
}

