<template>
  <div>
    <div class="container-fluid blogbar"> </div>
  <div class="container-fluid headerarea"> 
    <div class="menubar ">
      <div class="container">
      <div class="row">
        <div class="col-xs-12">
          <router-link to="/Blog">
            <div class="blogbutton" >
              blog
            </div>
          </router-link>
        </div>
        <div class="col-xs-12">
          <nav class="navbar navbar-default">
            <div class="row">
              <div class="col-xs-12 col-md-12 col-lg-10">
                <div class="navbar-header">
                  <button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#main-navbar" aria-expanded="false" aria-controls="navbar">
                    <span class="sr-only">Toggle navigation</span>
                    <span class="icon-bar"></span>
                    <span class="icon-bar"></span>
                    <span class="icon-bar"></span>
                  </button>
                </div>
                <div class="navbar-collapse collapse" id="main-navbar">
                  <ul class="nav navbar-nav">
                    <li :class=" { 'active': route == '/' } " ><router-link to="/" alt="Home" title="Home">Home</router-link></li>

                    <li :class=" { 'active': route == '/Events' || route.substring(0,12) == '/EventDetail' } " ><router-link to="/Events" alt="Training Schedule" title="Training Schedule">Training Schedule</router-link></li>
                    <li :class=" { 'active': route == '/TrainingPresentations' } " ><router-link to="/TrainingPresentations" alt="Training Topics" title="Training Topics">Training Topics</router-link></li>
                    <li :class=" { 'active': route == '/SummerSessions' || route == '/SummerSessionDetail'} " ><router-link to="/SummerSessions" alt="Summer Sessions" title="Summer Sessions">Summer Sessions</router-link></li>
                    <li :class=" { 'active': route == '/InnovationForLawyers' } " ><router-link to="/InnovationForLawyers"  alt="Innovation for Lawyers" title="Innovation for Lawyers">Innovation for Lawyers</router-link></li>
                    <li :class=" { 'active': route == '/AboutJacinta' } " ><router-link to="/AboutJacinta" alt="About" title="About">About</router-link></li>

                    <li :class=" { 'active': route == '/Contact' } " ><router-link to="/Contact" alt="Contact" title="Contact">Contact</router-link></li>
                  </ul>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </div>
  </div>
  </div>
  <div class="row">
    <div class="logotopcontainer">
                <router-link to="/" class="jgtoplogo " alt="Home" title="Home">
                  <img src="../assets/images/jacintalogo.png">
                </router-link>
    </div>
  </div>
  </div>
  </div>
</template>

<script>
export default {
  name: 'Header',
  data() {
    return {
      route: this.$route.fullPath
    }
  }
}
</script>