<template>
  <div>
    <div class="footer-top">
    <div class="container">
      <div class="row">
        <div class="col-xs-12 col-sm-3  foot-left" style="text-align:left;">
          <router-link to="/" title="Home">HOME</router-link><br>
          <router-link to="/Events" title="Training Schedule">TRAINING SCHEDULE</router-link><br>	
          <router-link to="/TrainingPresentations" title="Training Topics">TRAINING TOPICS</router-link><br>
          <router-link to="SummerSessions" title="Summer Sessions">SUMMER SESSIONS</router-link><br>		
          <router-link to="/Blog" title="Blog">BLOG</router-link><br>								
          <router-link to="/InnovationForLawyers"  title="Innovation For Lawyers">INNOVATION FOR LAWYERS</router-link><br>
          <router-link to="/AboutJacinta" title="About">ABOUT</router-link><br>
          <router-link to="/Contact" title="Contact">CONTACT</router-link>
        </div>
        <div class="col-xs-12 col-sm-5 col-md-6  foot-mid" style="text-align:center;">
          <span style="color:white;font-weight:bold; text-align:center;">Never Cut What Can Be Untied</span>
          <img class="" src="../assets/images/Never-Cut-What-Can-Be-Untied23.png">
        </div>
        <div class="col-xs-12 col-sm-3 foot-right" style="text-align:right;">
          89 Hillsborough Street<br>
          Charlottetown, PEI<br>
          Canada, C1A 4W5<br>
          <br><br><b>
          902-367-3901</b><br>
          <a style="color:white;" href="mailto:jacinta@jacintagallant.ca"><b>jacinta@jacintagallant.ca</b></a>
        </div>
      </div>
    </div>
  </div>

  <div class="container-fluid footer-bottom">
    <div class="row footer">
      <div class="col-xs-12 col-sm-6 copyright">
        <span class="line">&copy;2021 Jacinta Gallant.</span> <span class="line"> All rights reserved.</span>
      </div>
      <div class="col-xs-12 col-sm-6 copyright" style="text-align: right;">
      </div>
    </div>
  </div>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  props: {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

</style>
